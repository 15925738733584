import styled, { css } from 'styled-components';

const Button = styled.button`
  display: block;
  padding: 7px 22px;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  transition: 0.3s;
  text-align: center;
  box-shadow: 0;
  border-radius: 6px;
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => theme.fontSize.m};
  }
  ${({ primary }) =>
    primary &&
    css`
        background: ${({ theme }) => theme.colors.greenPrimary};
        color: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.greenPrimary};
      @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.greenVariant};
      border: 2px solid ${({ theme }) => theme.colors.greenVariant};
    }
  }
      }
    `}
`;

export default Button;
