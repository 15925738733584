import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import HeaderMini from 'components/Header/HeaderMini';
import Gallery from 'components/Gallery/Gallery';

const Wrapper = styled.div`
  margin: 0 0 200px 0;

  @media screen and (max-width: 992px) {
    margin: 0 0 105px 0;
  }
`;

const GalleryPage = ({ data }) => {
  const PAGE = data.wpPage.PageGallery;

  const galleryList = PAGE.galleryList.map(({ galleryListImage }) => ({
    src: galleryListImage.localFile.childImageSharp.gatsbyImageData,
  }));

  return (
    <>
      <SEO title="Galeria zdjęć" />
      <Wrapper>
        <HeaderMini
          maxWidth="540px"
          spacing
          heading={PAGE.galleryBannerHeading}
          subheading={PAGE.galleryBannerSubheading}
        />
        <Gallery list={galleryList} />
      </Wrapper>
    </>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryPageGallery {
    wpPage(slug: { eq: "galeria" }) {
      PageGallery {
        galleryBannerHeading
        galleryBannerSubheading
        galleryList {
          galleryListImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default GalleryPage;
