import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Container from 'components/shared/Container';
import LazyImage from 'components/shared/LazyImage';
import Button from 'components/shared/Button';

const Wrapper = styled.section`
  & ${Col} {
    margin: 0 0 30px 0;
    & > div {
      width: 100% !important;
    }
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 300px;
  overflow: hidden;
`;

const Gallery = ({ list }) => {
  const pagination = 6;
  const [imagesVisible, setImagesVisible] = useState(pagination);

  return (
    <Wrapper>
      <Container>
        <Row>
          {list &&
            list.slice(0, imagesVisible).map((item, index) => (
              <Col key={index} lg={4}>
                <Zoom>
                  <ImageWrapper>
                    <LazyImage cover src={item.src} />
                  </ImageWrapper>
                </Zoom>
              </Col>
            ))}
        </Row>

        {list.length > imagesVisible && (
          <LoadMore>
            <Button
              primary
              onClick={() => setImagesVisible(imagesVisible + pagination)}
            >
              Zobacz więcej zdjęć{' '}
              {list.length - imagesVisible > 0 &&
                `(${list.length - imagesVisible})`}
            </Button>
          </LoadMore>
        )}
      </Container>
    </Wrapper>
  );
};

Gallery.propTypes = {
  list: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Gallery;
